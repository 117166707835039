import React, { memo } from "react";
import Form from "react-bootstrap/Form";

 function Limit({setlimit}) {

  const handlechange=(e)=>{
    setlimit(e.target.value)

  }

  return (
    <div>
    
      <Form.Select onChange={handlechange} aria-label="Default select example">
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="20">20</option>
        <option value="25">25</option>
      </Form.Select>
    </div>
  );
}
export default memo(Limit)
