import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { Formik, field, ErrorMessage } from "formik";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ForgotPass } from "../Redux/Actions/adminAction";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email(<span style={{ color: 'red' }}>Invalid Email Address</span>)
    .required(<span style={{ color: 'red' }}>Required</span>),
});

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="text-center">Forgot Password</h2>
                <Formik
                  initialValues={{ email: "" }}
                  validationSchema={validationSchema}
                  onSubmit={async (values) => {
                    console.log(values);
                    try {
                      const data = await dispatch(ForgotPass(values));
                      console.log(data);

                      if (data?.payload?.data?.success === true) {
                        toast.success(data?.payload?.data?.message);

                        
                        setTimeout(() => {
                            toast.dismiss();
                            navigate("/otp", { state: { email: values.email } });
              
                        }, 2000);
                      } else {
                        toast.error(" Invalid Credentials ");
                      }
                    } catch (error) {
                      console.log(error, " something went wrong");
                    }
                  }}
                >
                  {({ values, handleChange, handleSubmit }) => (
                    <Form>
                      <div className="form-set">
                        <Form.Group className="mb-3">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Type Here"
                            name="email"
                            as={Form.Control}
                            onChange={handleChange}
                            value={values.email}
                          />
                          <ErrorMessage name="email" component="div"/>
                        </Form.Group>
                      </div>

                      <button
                        // to="/otp"
                        variant="primary"
                        type="button"
                        className="submit"
                        onClick={handleSubmit}
                      >
                        Send OTP
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
