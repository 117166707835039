import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  changeStatus,
  userListing,
  userListingDownload,
} from "../Redux/Actions/adminAction";
import Search from "../Components/Layout/Search";
import ShowingResult from "../Components/Layout/ShowingResult";
import Limit from "../Components/Layout/Limit";
import { toast } from "react-toastify";
import Pagination from "../Components/Layout/Pagination";
import * as XLSX from "xlsx";

export default function UserManagement() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.usermgmtData.userList);
  console.log(data, "userList");

  const [page, setpage] = useState("1");
  const [search, setsearch] = useState("");
  const [limit, setlimit] = useState("10");
  const [flag, setflag] = useState(true);
  const [pageType, setPageType] = useState("");

  useEffect(() => {
    dispatch(userListing({ page, search, limit, type: "" }));
  }, [page, search, limit, flag]);

  // const handlechange = (e) => {
  //   console.log(e.target.value);
  //   setPageType(e.target.value);

  //   dispatch(userListing({ page, search, limit, type: e.target.value }));
  // };

  const fetchDataAndExportToExcel = useCallback(async () => {
    try {
      const response = await dispatch(userListingDownload());
      console.log(response);

      const allData = response?.payload?.data?.users;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  }, [dispatch]);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>User Management</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  {/* <Form.Control type="search" placeholder="Keyword Search.." /> */}
                  <Search setsearch={setsearch} />
                </Form.Group>
              </Col>
              {/* <Col xxl={2} xl={3} lg={4} md={4}>
                <Form.Select
                  onChange={handlechange}
                  aria-label="Default select example"
                >
                  <option value="">All</option>
                  <option value="1">Active</option>
                  <option value="0">In-Active</option>
                </Form.Select>
              </Col> */}
              <Col
                xxl={9}
                xl={7}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link onClick={fetchDataAndExportToExcel}>DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/UserManagement" className="active-tab">
                  All
                </Link>
              </li>
              {/* <li>
                <Link to="/ActiveUser"> Active</Link>
              </li>
              <li>
                <Link to="/SuspendUser"> Suspened</Link>
              </li> */}
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                <ShowingResult page={page} limit={limit} />
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span> <Limit setlimit={setlimit} />
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Profile picture</th>
                <th>Profile name </th>

                <th>Email</th>
                {/* <th>Profile Image</th> */}
                {/* <th>Position</th> */}
                {/* <th>Location</th>
                <th>Status</th> */}
                <th>Profile Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.users?.map((user, index) => {
                const serialNumber = (page - 1) * limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>

                    <td className="user-img">
                      <img src={require("../Assets/Images/user.png")} />
                    </td>
                    <td>
                      <Link to={`/CustomerProfile/${user?._id}`}>
                        <b> {user?.name || "N/A"}</b>
                      </Link>
                    </td>

                    <td>{user?.email || "N/A"}</td>
                    {/* <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td> */}
                    <td>
                      <div className="select-box">
                        <Form.Select
                          onChange={(e) => {
                            dispatch(
                              changeStatus({
                                userId: user?._id,
                                type: e.target.value,
                              })
                            ).then((res) => {
                              if (res?.payload?.success) {
                                toast.success(res?.payload?.message);
                                setflag(!flag);
                              } else {
                                toast.error(res?.payload?.message);
                              }
                            });
                          }}
                          value={user?.is_active}
                          aria-label="Default select example"
                        >
                          <option value="0">Temporary Banned</option>
                          <option value="1">Active</option>
                        </Form.Select>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {data?.data?.users?.length == 0 && (
            <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
            </div>
          )}
        </div>
      </Container>

      <Pagination
        totalstuff={data?.data?.totalPages}
        limit={data?.data?.limit}
        setpages={setpage}
        search={search}
      />
    </Layout>
  );
}
