import React, { memo } from "react";
import {useDispatch,useSelector} from "react-redux"

function ShowingResult({ page, limit }) {
  const data = useSelector((state) => state.usermgmtData.userList);
  console.log(data, "userList");

  return (
    <div>
      <div className="show-results">
        {" "}
        Showing {1 + (page - 1) * limit} -{" "}
        {data?.data?.users?.length +
          (page - 1) * limit}{" "}
        of {data?.data?.totalCount} results
      </div>
    </div>
  );
}

export default memo(ShowingResult);
