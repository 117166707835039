import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";

export default function Pagination({ totalstuff,limit,setpages,search }) {
  
  // const handlePageClick = (event) => {
  //   // paginationevent(event.selected + 1);
  // };

  return (
    <div className="pagination-wrapper next-btn-fix set-pagi-btm">
      <ReactPaginate
        breakLabel="..."
        nextLabel={"next"}
        onPageChange={(e) => setpages(e?.selected + 1)}
        pageRangeDisplayed={totalstuff}
        pageCount={search? 1 :totalstuff}
        previousLabel={"prev"}
        renderOnZeroPageCount={null}
        // containerClassName={'pagination justify-content-center'}
        //   pageClassName={'page-item'}
        //   pageLinkClassName={'page-link'}
        //   previousClassName={'page-item'}
        //   previousLinkClassName={'page-link'}
        //   nextClassName={'page-item'}
        //   nextLinkClassName={'page-link'}
        //   breakClassName={'page-item'}
        //   breakLinkClassName={'page-link'}
        // activeClassName={"selected"}
        // pageClassName={(pageNumber) =>
        //   `page-item ${getPageItemClassName(pageNumber + 1)}`
        // }
      />
    </div>
  );
}
