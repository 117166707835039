import React from "react";
import Login from "./pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginOtp from "./pages/LoginOtp";
import ForgotPassword from "./pages/ForgotPassword";
import UserManagement from "./pages/UserManagement";
import Support from "./pages/Support";
import Dashboard from "./pages/Dashboard";
import Supportchat from "./pages/SupportChat";
import CustomerProfile from "./pages/CustomerProfile";
import Notification from "./pages/Notification";
import AddNotification from "./pages/AddNotification";
import ChangePassword from "./pages/ChangePassword";
import WorkoutManagement from "./pages/WorkoutManagement";
import ForgotPasswordtwo from "./pages/ForgotPasswordtwo";
import RecipesManagement from "./pages/RecipesManagement,";
import AddRecipes from "./pages/AddRecipes";
import AddWorkout from "./pages/AddWorkout";
import Blogs from "./pages/Blogs";
import AddBlog from "./pages/AddBlog";
import FaqsManagement from "./pages/FaqsManagement";
import AddFaq from "./pages/AddFaq";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/AddFaq" element={<AddFaq />} />
          <Route path="/FaqsManagement" element={<FaqsManagement />} />
          <Route path="/AddBlog" element={<AddBlog />} />
          <Route path="/Blogs" element={<Blogs />} />
          <Route path="/AddWorkout" element={<AddWorkout />} />
          <Route path="/RecipesManagement" element={<RecipesManagement />} />
          <Route path="/AddRecipes" element={<AddRecipes />} />
          <Route path="/" element={<Login />} />
          <Route path="/Otp" element={<LoginOtp />} />
          <Route path="/Forgot-Password" element={<ForgotPassword />} />
          <Route path="/UserManagement" element={<UserManagement />} />
          <Route path="/Support" element={<Support />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/CustomerProfile/:id" element={<CustomerProfile />} />
          <Route path="/Supportchat" element={<Supportchat />} />
          <Route path="/Notification" element={<Notification />} />
          <Route path="/AddNotification" element={<AddNotification />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />
          <Route path="/WorkoutManagement" element={<WorkoutManagement />} />
          <Route path="/ChangePasswordtwo" element={<ForgotPasswordtwo />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
