// Logout

import { toast } from "react-toastify";
import Adminapi from "../../services/AdminApi";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const LogOut = createAsyncThunk("LogOut", async () => {
  console.log("first");
  const { data } = await Adminapi.post("/auth/logout");

  if (data?.success) {
    toast.success(data?.message);
    window.location.href = "/";
  } else {
    toast.error(data?.message);
  }
});

//   change password

export const Changepassword = createAsyncThunk(
  "Changepassword",
  async (details) => {
    const data = await Adminapi.post("auth/changePassword", details);
    console.log(data);
    return data;
  }
);

// Forgot Password

export const ForgotPass = createAsyncThunk(
  "ForgotPassword",
  async (details) => {
    const data = await Adminapi.post("/auth/forgotPassword", details);
    return data;
  }
);

//  OtpVerify

export const OtpVerify = createAsyncThunk("OtpVerify", async (details) => {
  const data = await Adminapi.post("/auth/verifyOtp", details);
  return data;
});

// resetpassword

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (details) => {
    const data = await Adminapi.post("/auth/resetPassword", details);
    return data;
  }
);

//   user listing

export const userListing = createAsyncThunk("userListing", async (details) => {
  const { data } = await Adminapi.get(
    `/userManagement?page=${details?.page}&limit=${details?.limit}&name=${details?.search}&type=${details?.type}`
  );

  return data;
});

//  change profile status and suspend Account

export const changeStatus = createAsyncThunk(
  "changeStatus",
  async (details) => {
    const { data } = await Adminapi.post(`/activateDeactivateUser`, details);

    return data;
  }
);

// Get user profile

export const getUserProfile = createAsyncThunk(
  "getUserProfile",
  async (details) => {
    const {data} = await Adminapi.get(`/userProfile?userId=${details.userId}`);
    return data;
  }
);


// Delete Account

export const DelecteAccount = createAsyncThunk(
  "DelecteAccount",
  async (details) => {
    const data = await Adminapi.post("/deleteAccount", details);
    return data;
  }
);

// user list download

export const userListingDownload = createAsyncThunk(
  "userListingDownload",
  async (details) => {
    const { data } = await Adminapi.get(`/userManagement`);

    return data;
  }
);


