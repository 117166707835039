import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Figure, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  DelecteAccount,
  changeStatus,
  getUserProfile,
} from "../Redux/Actions/adminAction";
import logo from "../Assets/Images/placeholder 1 .png";
import { toast } from "react-toastify";

export default function AddWorkout() {
  const [show, setshow] = useState(false);
  const [showdel, setshowdel] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.usermgmtData.userdetails);
  console.log(data, "userList");
  let BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

  const { id } = useParams();

  useEffect(() => {
    dispatch(getUserProfile({ userId: id }));
  }, [id]);

  const handledelclick = async () => {
    setshowdel(true);
  };

  const handleclick = async () => {
    setshow(true);
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Add Workout</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/WorkoutManagement">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center p-0"
            ></Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4">
        <Row className="pb-4">
          <Col lg={12}>
            <Row>
              <Col lg={8} className="mx-auto">
                <div className="user-profile-main">
                  <Row>
                    <Col lg={12} xl={12} xxl={12}>
                      <div className="property-main mt-0">
                        <div className="customer-form-new border-none">
                          <Row>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Add Video</Form.Label>
                                <div className="docu-upd-box">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="74"
                                    height="70"
                                    viewBox="0 0 74 70"
                                    fill="none"
                                  >
                                    <g opacity="0.3">
                                      <path
                                        d="M5.75248 52.9423C8.8635 49.8307 11.7839 46.9049 14.7086 43.9835C15.0032 43.6896 15.2849 43.3871 15.6055 43.1278C17.135 41.8658 19.1628 41.8788 20.6273 43.2185C21.7799 44.2773 22.8631 45.4139 23.9767 46.516C25.1769 47.7044 26.1301 47.7131 27.3217 46.5246C33.2707 40.5996 39.2111 34.6702 45.1602 28.7409C47.383 26.5282 49.3891 26.5109 51.5946 28.7106C56.872 33.9701 62.1495 39.2296 67.4183 44.4934C67.648 44.7225 67.8083 45.012 68.0033 45.2756C68.0986 45.2281 68.1939 45.1849 68.2892 45.1373C68.2892 44.8348 68.2892 44.5323 68.2892 44.2298C68.2892 40.6298 68.2849 37.0255 68.2892 33.4256C68.2936 31.524 69.4158 30.2707 71.1186 30.2664C72.8128 30.2621 73.9697 31.524 73.974 33.404C73.9783 40.6946 73.9567 47.9897 73.9913 55.2803C74 57.2035 74.0823 59.105 73.493 60.9763C71.8032 66.3655 66.8897 70.0043 61.2309 70C45.8361 69.9827 30.4457 69.9697 15.0509 69.9308C13.3437 69.9265 11.5889 69.987 9.93806 69.6327C3.76801 68.2929 -0.356908 62.7007 0.0243873 56.4385C0.0460518 56.1144 0.0677163 55.7903 0.0677163 55.4662C0.0677163 43.0629 0.0850479 30.664 0.0503847 18.2651C0.0460518 16.3376 -0.00594305 14.4404 0.59633 12.5734C2.28616 7.33124 7.18234 3.71399 12.7068 3.71399C21.9445 3.71399 31.1779 3.75289 40.4157 3.76153C41.7849 3.76153 42.8421 4.24124 43.4184 5.5291C43.869 6.53173 43.6264 7.76341 42.8551 8.57157C42.1662 9.28897 41.2996 9.44455 40.342 9.44455C31.8755 9.43158 23.4091 9.4359 14.9426 9.44023C14.2623 9.44023 13.582 9.46183 12.9018 9.52666C8.11825 9.96747 5.93446 12.2839 5.76981 17.081C5.75248 17.552 5.75681 18.0274 5.75681 18.4985C5.75681 29.5706 5.75681 40.6385 5.75681 51.7106C5.75248 52.0261 5.75248 52.3416 5.75248 52.9423Z"
                                        fill="black"
                                        fill-opacity="0.5"
                                      />
                                      <path
                                        d="M66.3654 13.2908C66.3654 14.9417 66.3958 16.5278 66.3568 18.1095C66.3264 19.3455 65.4425 20.3871 64.29 20.6896C63.1201 20.9964 61.7942 20.5513 61.2093 19.4881C60.8973 18.922 60.75 18.2132 60.7197 17.5607C60.646 16.1561 60.698 14.7473 60.698 13.239C59.1295 13.239 57.6606 13.2563 56.1918 13.2347C54.4889 13.2087 53.2627 12.1326 53.1631 10.6244C53.0548 8.94323 54.203 7.63808 55.8971 7.58622C57.431 7.53868 58.9692 7.54733 60.5073 7.53004C60.5333 7.53004 60.555 7.49979 60.7023 7.39607C60.7023 6.4064 60.6937 5.32166 60.7067 4.24124C60.7153 3.59299 60.685 2.93609 60.7977 2.30512C61.0533 0.844394 62.3532 -0.110698 63.809 0.0103092C65.2085 0.131316 66.3221 1.26792 66.3741 2.70704C66.4174 3.97329 66.3871 5.24387 66.3914 6.51012C66.3914 6.82561 66.3914 7.14109 66.3914 7.58622C68.1116 7.58622 69.7278 7.55597 71.3439 7.59487C72.5225 7.62512 73.4584 8.3987 73.8353 9.53098C74.2036 10.6244 73.909 11.8906 72.9341 12.5346C72.3535 12.9192 71.5952 13.1482 70.8933 13.2131C69.7191 13.3211 68.5319 13.239 67.349 13.2433C67.0457 13.2476 66.7554 13.2736 66.3654 13.2908Z"
                                        fill="black"
                                        fill-opacity="0.5"
                                      />
                                      <path
                                        d="M26.5721 25.5169C26.5764 28.1445 24.4663 30.2664 21.8492 30.2707C19.2451 30.2751 17.1133 28.1402 17.109 25.5299C17.1047 22.9196 19.2278 20.7846 21.8362 20.7803C24.4533 20.7717 26.5721 22.8893 26.5721 25.5169Z"
                                        fill="black"
                                        fill-opacity="0.5"
                                      />
                                    </g>
                                  </svg>
                                  <input type="file" />
                                </div>
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Workout title</Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder="dummy"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Instruction</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={5}
                                  placeholder="Dummy"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Benefit tips</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={5}
                                  placeholder="Dummy"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <div className="d-flex justify-content-between align-items-center">
                                  <Form.Label>Focus Area</Form.Label>
                                  <div className="">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="21"
                                      height="21"
                                      viewBox="0 0 21 21"
                                      fill="none"
                                    >
                                      <line
                                        x1="9.52344"
                                        x2="9.52344"
                                        y2="20.308"
                                        stroke="#84807C"
                                      />
                                      <line
                                        x1="10.6523"
                                        x2="10.6523"
                                        y2="20.308"
                                        stroke="#84807C"
                                      />
                                      <line
                                        x1="20.3144"
                                        y1="9.52536"
                                        x2="0.00480108"
                                        y2="9.76062"
                                        stroke="#84807C"
                                      />
                                      <line
                                        x1="20.3144"
                                        y1="10.6543"
                                        x2="0.00480108"
                                        y2="10.8895"
                                        stroke="#84807C"
                                      />
                                    </svg>
                                  </div>
                                </div>
                                <Form.Control
                                  className="mb-3"
                                  type="email"
                                  placeholder="dummy"
                                />
                                <Form.Control
                                  type="email"
                                  placeholder="dummy"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <div className="d-flex justify-content-between align-items-center">
                                  <Form.Label>Timing</Form.Label>
                                  <div className="profile-cmm-checkbox">
                                    <div>
                                      <input type="checkbox" />
                                    </div>
                                  </div>
                                </div>
                                <Form.Control
                                  className="mb-3"
                                  type="email"
                                  placeholder="dummy"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <div className="d-flex justify-content-between align-items-center">
                                  <Form.Label>Repetitions</Form.Label>
                                  <div className="profile-cmm-checkbox">
                                    <div>
                                      <input type="checkbox" />
                                    </div>
                                  </div>
                                </div>
                                <Form.Control
                                  className="mb-3"
                                  type="email"
                                  placeholder="dummy"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Suitable for </Form.Label>
                                <div className="profile-cmm-checkbox">
                                  <div>
                                    <input type="checkbox" />
                                    <p>Full body</p>
                                  </div>
                                  <div>
                                    <input type="checkbox" />
                                    <p>Arms</p>
                                  </div>
                                  <div>
                                    <input type="checkbox" />
                                    <p>Abs</p>
                                  </div>
                                  <div>
                                    <input type="checkbox" />
                                    <p>Butt</p>
                                  </div>{" "}
                                  <div>
                                    <input type="checkbox" />
                                    <p>Leg</p>
                                  </div>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Level </Form.Label>
                                <div className="profile-cmm-checkbox">
                                  <div>
                                    <input type="checkbox" />
                                    <p>Begginers</p>
                                  </div>
                                  <div>
                                    <input type="checkbox" />
                                    <p>Intermediate</p>
                                  </div>
                                  <div>
                                    <input type="checkbox" />
                                    <p>Advance</p>
                                  </div>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Designed for </Form.Label>
                                <div className="profile-cmm-checkbox">
                                  <div>
                                    <input type="checkbox" />
                                    <p>Male</p>
                                  </div>
                                  <div>
                                    <input type="checkbox" />
                                    <p>Female</p>
                                  </div>
                                </div>
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                    {/* <Col lg={8} xl={9} xxl={10}>
                      <div className=" px-3 py-4 h-100"></div>
                    </Col> */}
                  </Row>
                </div>
              </Col>

              <Col lg={12}>
                <div className="page-bottom-btn mt-4">
                  <button>Create</button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal show={show} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to suspend this Account
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button
            className="theme-btn"
            onClick={() => {
              dispatch(changeStatus({ userId: id, type: 0 })).then((res) => {
                if (res?.payload?.success) {
                  toast.success(res?.payload?.message);
                  setshow(false);
                } else {
                  toast.error(res?.payload?.message);
                }
              });
            }}
          >
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showdel} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to Delete this Account
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button
            className="theme-btn"
            onClick={() => {
              dispatch(DelecteAccount({ userId: id, type: 0 })).then((res) => {
                if (res?.payload?.success) {
                  toast.success(res?.payload?.message);
                  setshow(false);
                } else {
                  toast.error(res?.payload?.message);
                }
              });
            }}
          >
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowdel(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
