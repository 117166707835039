import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";

export default function WorkoutManagement() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Workout Management</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control type="search" placeholder="Search.." />
                </Form.Group>
              </Col>

              <Col className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn">
                  <Link to="/AddWorkout">ADD Workout</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="" className="active-tab">
                  ALL
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">Showing 1 - 10 of 20 results</div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>
                <Form.Select aria-label="Default select example">
                  <option>10</option>
                  <option value="1">11</option>
                  <option value="2">12</option>
                  <option value="3">13</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Workout Title</th>
                <th>Instruction</th>
                <th>Video</th>
                <th>ACTION </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Dummyname</td>
                <td>Ipsum has been the industry's stan........</td>
                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>
                  <button className="account-btn common-colr-btn">
                    Remove
                  </button>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Container>

      <div className="next-btn-fix">
        <Link to="#">Next</Link>
      </div>
    </Layout>
  );
}
