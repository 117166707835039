
import axios from "axios"

const Adminapi=axios.create({
    baseURL:process.env.REACT_APP_ADMIN_BASE_URL,
    // baseURL:"https://bluebird-api.bosselt.com/api/admin",
    headers:{
        Authorization:sessionStorage.getItem("token")
    }

})

Adminapi.interceptors.request.use(
    (response)=>{
        return response
    },
    (error)=>{
        if (error?.response?.status === 401) {
            window.location.href = "/";
          }
          return error.response;

    }
)

export default Adminapi